.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Img-coming-soon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 40vw;
}





